// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-en-blog-tsx": () => import("./../../../src/pages/en/blog.tsx" /* webpackChunkName: "component---src-pages-en-blog-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-portfolio-tsx": () => import("./../../../src/pages/en/portfolio.tsx" /* webpackChunkName: "component---src-pages-en-portfolio-tsx" */),
  "component---src-pages-en-sdaas-tsx": () => import("./../../../src/pages/en/sdaas.tsx" /* webpackChunkName: "component---src-pages-en-sdaas-tsx" */),
  "component---src-pages-en-successful-submission-tsx": () => import("./../../../src/pages/en/successful-submission.tsx" /* webpackChunkName: "component---src-pages-en-successful-submission-tsx" */),
  "component---src-pages-en-work-with-us-tsx": () => import("./../../../src/pages/en/work-with-us.tsx" /* webpackChunkName: "component---src-pages-en-work-with-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-sdaas-tsx": () => import("./../../../src/pages/sdaas.tsx" /* webpackChunkName: "component---src-pages-sdaas-tsx" */),
  "component---src-pages-successful-submission-tsx": () => import("./../../../src/pages/successful-submission.tsx" /* webpackChunkName: "component---src-pages-successful-submission-tsx" */),
  "component---src-pages-work-with-us-tsx": () => import("./../../../src/pages/work-with-us.tsx" /* webpackChunkName: "component---src-pages-work-with-us-tsx" */),
  "component---src-templates-post-profile-tsx": () => import("./../../../src/templates/PostProfile.tsx" /* webpackChunkName: "component---src-templates-post-profile-tsx" */)
}

